<template>
  <div class="timelinebox">
    <WeekLine :weeks="weeks"/>
    <DrugLine v-for="item in data" :key="item.id" :drugtimeline="item" :weeks="weeks"/>
  </div>
</template>

<script>
import DrugLine from '@/components/timeline/DrugLine.vue'
import WeekLine from '@/components/timeline/WeekLine.vue'

export default {
  name: 'TimeLine',
  components:{
      DrugLine,
      WeekLine
  },
  props:{
    data: Array,
  },
  computed:{
    weeks:function(){
      let w_arr =[];
      let l_arr = this.data.map(function(drug) {
                    return drug.infusions.length;
                  });
      let l = Math.max.apply(null, l_arr);
      for (var i = 0; i < l; i++) {
        let summ=0;
        for (var j = 0; j < this.data.length; j++)
          if(!!this.data[j].infusions[i])
            summ+=this.data[j].infusions[i];
        if(summ>0) w_arr.push(i);
      }
      return w_arr;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.timelinebox{
  background: linear-gradient(0deg, rgb(255,255,255,0.4), transparent);
  padding: 0px 10px 10px 20px;
  margin: 10px 0 17px 0;
}
</style>