<template>
  <div class="line" >
    <div class="drugcol"></div>
    <div class="weekline">
      <div class="col"        
        v-for="week in weeks"
        :key="week.id"
        :style="{ width: calcWidth}" >
        <p>{{ week+1 }}</p>
      </div>
      <div>
        <p class="week_title">неделя</p>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'WeekLine',
  props:{
    weeks: Array
  },
  computed:{
    calcWidth:function(){
      return 800/this.weeks.length + 'px';
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.line, .weekline{
  display:flex;
  flex:1 1 auto;
  align-items: normal;
}
.col{
  display:inline-block;
  color:#787572;
}
.col p{
  text-align:center;
}
.week_title{
  color:#787572;  
}
.drugcol{
  width:120px;
  }
</style>