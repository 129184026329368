<template>
  <div class="line" >
    <div class="drugcol">
      <p>
        <span class="drugname" :style="{color:drugtimeline.drug.color}">{{drugtimeline.drug.name}}</span><br>
        <span class="drugdose" v-html="drugtimeline.drug.dose"></span>
      </p>
    </div>
    <div class="infusions">
      <div class="hl"></div>
      <div
        class="inf"
        v-for="point in points"
        :key="point.id"
        :style="{ width: calcWidth, color: drugtimeline.drug.color}" >
        <p>
          <span v-if="point !== -1" :style="{ 'border-color': drugtimeline.drug.color}">{{ point }}</span> 
        </p>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'DrugLine',
  props:{
    drugtimeline: Object,
    weeks: Array
  },
  computed:{
    calcWidth:function(){
      return 800/this.weeks.length + 'px';
    },
    points:function(){
      let p=[];
      let cnt=1;
      for(let i=0; i<this.weeks.length; i++){
        if(this.drugtimeline.infusions[this.weeks[i]]==1)
          p[i]=cnt;
        else
          p[i]=-1;
      cnt++;
      }
      return p;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.line{
  display:flex;
}
.col{
  display:flex;
  align-items: center;
  justify-content: center;
  color:#787572;
}
.infusions{
  padding-right:53px;
}
.inf{
  z-index: 100;
  position: relative;
  display:inline-block;
  text-align:center;
}
.infusions p{
  margin: auto;
}
.infusions span{
  border: 6px solid #555555;
  background: #DFECF1;
  box-sizing: border-box;
  border-radius: 31px;
  width: 30px;
  height: 30px;
  display: block;
  margin: auto;
  line-height: 19px;
  font-size: 11px;
}
.drugcol{
  display:flex;
  align-items: center;
  width:120px;
  }
.drugcol p{
  margin: 4px 0;
}
.drugname{
  font-weight: 600;
  color:#787572;
  margin: 5px 0px;
}
.drugdose{
  margin: 5px 0px;
  color:#787572;
}
.hl{
  height: 15px;
  position: absolute;
  width: 854px;
  border-bottom: 1px solid #787572;
}
</style>