<template>
  <div>
    <TimeLine :data='timeline'/>
    <div class="bot">
      <div class="week-switcher">
        <div class="weight">
          <input :value="weight" v-on:change="e=>updWeight(e)" min="10" max="150">
          <div>
            <div class="arrows" v-on:click="updWeight(1)"><font-awesome-icon icon="caret-up" /></div>
            <div class="arrows" v-on:click="updWeight(-1)"><font-awesome-icon icon="caret-down" /></div>
          </div>
        </div>
        <p class="input-subtitle none-selectable">Вес пациента (кг)</p>

          <Btn v-for="w in weeks" :key="w.id" :name="'week'+w.num" :title="w.title" :active="w.active" v-on:click.native="weekCheck(w)"/>
      </div>
      <div>
        <div v-if="showProgSwitch" class="checkbox-frame"
            @mouseover="flOver = true" @mouseleave="flOver = false">
          <CheckBox title="Участие в программе целевой передачи препарата Ипилимумаб" 
            :checked="programflag" v-on:click.native="flagCheck()" />
          &nbsp;<Footnote v-if="showProgSwitch" :remOver = "flOver"/>
      </div>
        <div class="results-frame">
          <div class="middle">
            <Table :data='drugs_table'/>
            <div v-if="flAreaRem" class="substr">* Стоимость терапии рассчитывается с допущением, что у пациентов стандартная площадь тела, равная 1,79 м<sup>2</sup></div> 
          </div>
          <VerticalGraph :data='drugs_graph' :flag='programflag' :twoBars='showProgSwitch'/>
          
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import TimeLine from '@/components/timeline/TimeLine.vue'
import Table from '@/components/table/Table.vue'
import VerticalGraph from '@/components/graphic/VerticalGraph.vue'
import Btn from '@/components/Btn.vue'
import CheckBox from '@/components/CheckBox.vue'
import Footnote from '@/components/Footnote.vue'
import drugList from '@/views/drugStore';
import schemas from '@/views/schemas';
import dataStore from '@/views/data';
import { eventBus } from '@/main'
export default {
  components:{
    Table,
    TimeLine,
    VerticalGraph,
    Btn, CheckBox, Footnote
  },
  props:{
    week:Number,
    weight:Number,
    programflag:Boolean,
  },
  
  data:function(){
    return{
      method: dataStore.currMethod,
      refFl: 0,
      flAreaRem: false,
      localWeight: 75,
      flOver: false,

      nivo_1mg: {name:'Ниволумаб', dose:'1 мг/кг', color:'#03417A'},
      ipili_3mg: {name:'Ипилимумаб', dose:'3 мг/кг', color:'#00C013'},
      nivo_480mg: {name:'Ниволумаб', dose:'480 мг', color:'#0084BD'},

      weeks:[{title:'12 недель', num:12, active:false}, 
             {title:'24 недели', num:24, active:false}, 
             {title:'36 недель', num:36, active:false}, 
             {title:'52 недели', num:52, active:false}]
    }
  },
  computed:{

    showProgSwitch: function(){

      let showFlag = false;

      if(dataStore.currIndic<2) showFlag = true;

      return showFlag;
    },

    drugs_table:function(){

      let tableData = [], tableLine = [], methResult = [], len, lineData = {};
      let methData = schemas[this.method], drug, name, dose, total = 0, ref,cursPacks = [];
      let header = ['', 'Стоимость одной инфузии, руб', 'Cтоимость<br>курса, руб', 'Количество флаконов'];
      let tableFooter = [], flNivol = 0, flIpi = 0, flCombi = 0, priceCombi = 0;

      ref = this.refFl;
      tableData.push(header);
      methResult = dataStore.calcMethodResults(this.method, this.programflag);
      len = methResult.length;
      for(let cnt=0 ; cnt<len ; cnt++) {
          drug = methData.prepData[cnt].prepNum[0];
          name = drugList[drug].name;
          if(drug==3) {
            flIpi++;
            priceCombi += methResult[cnt].cursPrice
          }
          if(drug==1 || drug==2) { // Костыль для Ниволумаба
            flNivol++;
            if(flNivol==1) priceCombi += methResult[cnt].cursPrice
            name = name + ' 100/40 мг';
//              dose = '100/40 мг/флак<br>'
//              dose = dose + methData.prepData[cnt].dose;
          } else {
//            dose = '' + methData.prepData[cnt].dose;
          }
          dose = '' + methData.prepData[cnt].dose;
          if(methData.prepData[cnt].mode=='a') dose = dose + ' мг';
          if(methData.prepData[cnt].mode=='m') dose = dose + ' мг/кг';
          if(methData.prepData[cnt].mode=='s') {
            dose = dose + ' мг/м<sup>2 *</sup>';
            this.flAreaRem = true;
          }
          lineData = {};
          lineData.name = name;
          lineData.dose = dose;
          lineData.color = methData.prepData[cnt].color;
          tableLine = [];
          tableLine.push(lineData);
          tableLine.push(dataStore.padDigits(methResult[cnt].singlePrice));
          tableLine.push(dataStore.padDigits(methResult[cnt].cursPrice));
          cursPacks = methResult[cnt].cursPacks;
          if(cursPacks.length==1) tableLine.push(cursPacks[0]);
          else tableLine.push(''+ cursPacks[0] + '/' + cursPacks[1]);
          tableData.push(tableLine);
          if(flNivol && flIpi && flCombi==0) { // Выводим подитог по комбинации ипи+нивол
                flCombi++;
                tableFooter = [];
                tableFooter.push({name:'Итого комбинация'});
                tableFooter.push('');
                tableFooter.push(dataStore.padDigits(priceCombi));
                tableFooter.push('');
                tableData.push(tableFooter);
          }          
          total += methResult[cnt].cursPrice;
      }
      tableFooter = [];
      tableFooter.push({name:'Итого'});
      tableFooter.push('');
//      tableFooter.push(total);
      tableFooter.push(dataStore.padDigits(total));
      tableFooter.push('');
      tableData.push(tableFooter);
      return tableData;
      // return [
      //   ['', 'Стоимость одной инфузии, руб', 'Cтоимость<br>курса, руб', 'Количество флаконов'],
      //   [this.nivo_1mg, '200000', '2000000', '4'],
      //   [this.ipili_3mg, '100000', '1000000', '4'],
      //   [this.nivo_480mg, '500000', '5000000', '10'],
      //   [{name:'Итого'},'','8000000', '']
      // ]
    },
    drugs_graph:function(){

      let graphData = {}, ref;

      let len, barData = {}, origBar = [], optimBar = [], retData = {}, drugDesc = {};
      let methData = schemas[this.method], drug, name, methResult, optimResult, flOptim ;

      ref = this.refFl;
      flOptim = 0 ; methResult = dataStore.calcMethodResults(this.method, flOptim);
      flOptim = 1 ; optimResult = dataStore.calcMethodResults(this.method, flOptim);
      len = methResult.length;
      for(let cnt=0 ; cnt<len ; cnt++) {
          barData = {};
          drugDesc = {};
          drugDesc.color = methData.prepData[cnt].color;
          drugDesc.gcolor = methData.prepData[cnt].gcolor;//upd
          barData.drug = drugDesc;
          barData.cost = methResult[cnt].cursPrice;
          origBar.push(barData);
          barData = {};
          barData.drug = drugDesc;
          barData.cost = optimResult[cnt].cursPrice;
          optimBar.push(barData);
      }
      retData.origin = origBar;
      retData.optim = optimBar;
      return retData;

      // return {
      //   origin:[{drug:this.nivo_1mg, cost:2000000},{drug:this.ipili_3mg, cost:1000000},{drug:this.nivo_480mg, cost:5000000}],
      //   optim:[{drug:this.nivo_1mg, cost:2000000},{drug:this.ipili_3mg, cost:800000},{drug:this.nivo_480mg, cost:5000000}]
      // }
    },
    timeline:function(){
      
      let methData = schemas[this.method], len = methData.prepData.length;
      let drugSet = [], name, dose, color, drug, lineData = {}, retObj = {}, ref,
          timeLine;
      ref = this.refFl;
      for(let cnt=0; cnt<len; cnt++) {
          drug = methData.prepData[cnt].prepNum[0];
          name = drugList[drug].name;
          dose = '' + methData.prepData[cnt].dose;
          if(methData.prepData[cnt].mode=='a') dose = dose + ' мг';
          if(methData.prepData[cnt].mode=='m') dose = dose + ' мг/кг';
          if(methData.prepData[cnt].mode=='s') dose = dose + ' мг/м<sup>2</sup>';
          lineData = {};
          lineData.name = name;
          lineData.dose = dose;
          lineData.color = methData.prepData[cnt].color;
          retObj = {};
          retObj.drug = lineData;
          timeLine = methData.prepData[cnt].timeLine;
          timeLine = timeLine.slice(0,this.week);
          retObj.infusions = timeLine; 
          drugSet.push(retObj);
      }
      return drugSet;
      // [
      //   { drug:this.nivo_1mg, infusions:[1,0,0,1,0,0,1,0,0,1,] },//             0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1] },
      //   { drug:this.ipili_3mg, infusions:[1,0,0,1,0,0,1,0,0,1] },
      //   { drug:this.nivo_480mg, infusions:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,1] }
      // ]
    }
  },

  methods:{
    updWeight:function(num){
      let new_weight, val;

      if(num==1 || num==-1) new_weight=this.weight+num;
      else {
        val = num.target.value;
        val = parseInt(val);
        if(isNaN(val)) new_weight = 75;
        else new_weight = val;
      }
      if(new_weight==null) new_weight = 75;
      if(new_weight>150) new_weight = 150;
      if(new_weight<30) new_weight = 30;
      dataStore.patWeight = new_weight;
      dataStore.calcMethodResults(this.method, this.programflag);
      this.refFl = !this.refFl;
      eventBus.$emit('weight-change', new_weight);
    },
    weekCheck:function(w){
      for(let i=0; i<this.weeks.length; i++)
        this.weeks[i].active = false;
      w.active = true;
      eventBus.$emit('week-change', w.num);
      dataStore.currWeeks = w.num;
      dataStore.calcMethodResults(this.method, this.programflag);
      this.refFl = !this.refFl;
    },
    flagCheck:function(){
      eventBus.$emit('flag-change', !this.programflag);
      dataStore.calcMethodResults(this.method, this.programflag);
      this.refFl = !this.refFl;
    }
  },
  mounted(){
    for(let i=0; i<this.weeks.length; i++)
      if(this.weeks[i].num === this.week)
        this.weeks[i].active = true;
    this.method = dataStore.currMethod;  
  }
}
</script>

<style scoped>
.bot{
  display:flex;
  align-items: flex-start;
}
.week-switcher{
  width: 180px;
  margin-right: 10px;
}
.substr{
  text-align: center;
  font-size: 13px;
  color: #747474;
  margin-top: 8px;
  padding-bottom:30px;
}
.weight{
  display:flex;
}
.weight input{
  width: 148px;
  line-height: 34px;
  background: transparent;
  border: 3px solid #787572;
  margin-bottom: 5px;
  text-align: center;
  color: #787572;
  font-size: 16px;
  font-weight: 600;
}
.arrows{
  cursor:pointer;
  background: #787572;
  color: #fff;
  padding: 0px 5px;
  margin-bottom: 2px;
  margin-left: 2px;
}
.checkbox-frame {
  margin-left: 4px;
  display: flex;
  width: 590px;
}
.results-frame{
  display: flex;
}
.input-subtitle{
  margin: 0 0px 19px 0;
  font-size: 14px;
  text-align: center;
  color:#787572;
}
.middle{
  width: 534px;
  padding: 6px 5px 0 5px;
}
</style>